import React, { useState, useEffect } from "react"
import { styled } from "linaria/react"
import { colors } from "../theme"
const StyledForm = styled.form`
  width: 100%;
  display: flex;
  transition: all 0.5s ease-out;
  > input {
    height: 50px;
    border-radius: 0;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    padding: 10px;
    width: 70%;
  }
  input:focus {
    border-radius: 0;
  }
  > button {
    transition: all 0.5s ease-out;
    height: 50px;
    font-weight: 300;
    border-radius: 0;
    cursor: pointer;
    padding: 0 20px;
    text-transform: uppercase;
    border: none;
    background-color: ${colors.black};
    color: #fff;
    font-size: 20px;
    width: max-content;
  }
  > button:focus {
    outline: none;
    border: none;
  }
  > .sending {
  }
  > .success {
    background-color: green;
  }
  > .error {
    background-color: red;
  }
  @media screen and (max-width: 400px) {
    button {
      padding: 0 5px;
    }
  }
`

const StatusText = styled.span`
  transition: all 0.5s ease-out;
  opacity: 0;
`

const SubForm = props => {
  const [state, setState] = useState({
    email: "",
    id: props.id || "XyuQ7T",
  })
  const states = ["default", "sending", "success", "error"]
  const [status, setStatus] = useState(states[0])

  const handleSubmit = async e => {
    e.preventDefault()
    setStatus(states[1])
    let res = await fetch("https://nocturnaldevelopment.org/subscribe", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(state),
    })
    if (!res.ok) {
      setStatus(states[3])
      res.text().then(data => console.log(data))
      throw new Error(`HTTP Error ${res.status}`)
    } else {
      setStatus(states[2])
      res.text().then(data => console.log(data))
    }
  }
  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value.toLowerCase() })
  }

  const buttonText = status => {
    let text
    switch (status) {
      case "success":
        text = "Thanks!"
        break
      case "sending":
        text = "Sending"
        break
      default:
        text = "Submit"
        break
    }
    return text
  }

  const statusStyle = status => {
    let style
    switch (status) {
      case "success":
        style = { border: "1px solid green" }
        break
      case "error":
        style = { border: "1px solid red" }
        break
      default:
        style = { border: "1px solid rgba(0,0,0,0)" }
        break
    }
    return style
  }

  const statusTextStyle = status => {
    let style
    switch (status) {
      case "success":
        style = { opacity: "100" }
        break
      case "error":
        style = { opacity: "100" }
        break
      default:
        style = {}
        break
    }
    return style
  }

  const statusText = status => {
    let text
    switch (status) {
      case "success":
        text =
          "Success! Check your email for confirmation! Don't forget to check spam."
        break
      case "error":
        text = "Something went wrong! Make sure your email is correct."
        break
      default:
        text = ""
        break
    }
    return text
  }

  const resetStatus = () => {
    setTimeout(() => {
      setStatus(states[0])
    }, 7000)
  }

  useEffect(() => {
    if (status !== "default" && status !== "sending") {
      resetStatus()
    }
  }, [status])

  return (
    <>
      <StyledForm
        name="newsletter"
        method="post"
        status={status}
        onSubmit={e => handleSubmit(e)}
        style={statusStyle(status)}
      >
        <input
          aria-label="email"
          placeholder="YOUR EMAIL..."
          type="email"
          name="email"
          onChange={e => handleChange(e)}
        />
        <button className={`${status}`} type="submit">
          {buttonText(status)}
        </button>
      </StyledForm>
      <StatusText style={statusTextStyle(status)}>
        {statusText(status)}
      </StatusText>
    </>
  )
}

export default SubForm
